import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { OFFERS_ROUTE_URLS, PHONES_SHOP_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS, ROUTE_URLS, SHOP_ROUTE_URLS } from 'src/app/app.routes.names';
import { AppState } from 'src/app/app.service';
// import Swiper, { Autoplay, EffectFade, Navigation, Pagination, SwiperOptions } from 'swiper';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})

export class BannersComponent implements OnInit, OnDestroy, AfterViewInit {
  public showPromoBanner = false;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public ROUTE_URLS = ROUTE_URLS;
  public PHONES_SHOP_ROUTE_URLS = PHONES_SHOP_ROUTE_URLS;
  public OFFERS_ROUTE_URLS = OFFERS_ROUTE_URLS;
  public index = 0;
  public showNewYearBanner = false;
  public showChristmasBanner = false;
//  public bannerSwiperConfig: SwiperOptions = {
//     centeredSlides: true,
//     speed: 1000,
//     direction: 'horizontal',
//     slidesPerView: 1,
//     keyboard: true,
//     effect: 'fade',
//     fadeEffect: {
//       crossFade: true
//     },
//     pagination: {
//       el: '.slider-pagination',
//       renderBullet: (index, className) => `<li style="z-index: 10" class="slick-active ${className}"><button ></button></li>`,
//       clickable: true
//     },
//     observeParents: true,
//     observer: true,
//     zoom: {
//       toggle: false
//     },
//     setWrapperSize: false,
//     loop: false,
//     navigation: {
//       nextEl: '.swiper-button-next',
//       prevEl: '.swiper-button-prev',
//     }
//   }; 
  public showPromoForAperiodOfTime;
  public showMainBanner: boolean = false;
  public currentTime;
  public targetTime;
  private checkInterval: any;
  private alive = true;

  constructor(
    public appState: AppState,
    private changeDetector: ChangeDetectorRef,
    private router: Router
  ) {
    this.currentTime = new Date();
    this.targetTime = new Date('2024-10-31T00:00:00-05:00');
    // this.checkBannerRemovalTime();
    const firstDate = new Date('2025/01/01').getTime();
    const lastDate = new Date('2025/01/02').getTime();
    const currentDate = new Date().getTime();
    this.showNewYearBanner = firstDate < currentDate && currentDate < lastDate;
    this.showChristmasBanner = firstDate > currentDate;
  }

  ngOnInit(): void {
    this.showMainBanner = true;
    this.changeDetector?.detectChanges();
    this.appState.showPromoForAperiodOfTimeObs.pipe(takeWhile(() => this.alive)).subscribe(res => {
      this.showPromoForAperiodOfTime = res;
      this.changeDetector?.detectChanges();
    });
    this.changeDetector?.detectChanges();
    // let swiper = new Swiper('.banners-swiper-container', {
    //   // Enable lazy loading
    //   modules: [Navigation, Pagination, EffectFade, Autoplay],
    //   hashNavigation: true,
    //   lazy: true,
    //   autoplay: {
    //     delay: 10000,
    //     stopOnLastSlide: false,
    //     disableOnInteraction: false,
    //   },
    //   preventClicks: false,
    //   preventClicksPropagation: false,
    //   ...this.bannerSwiperConfig
    // });
    // this.changeDetector?.detectChanges();
  }

  ngAfterViewInit() {
    // this.changeDetector?.detectChanges();
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  public goToOfferLanding(): void {
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.FOURTH_OF_JULY}`]);
  }
  public goToPlans(months?: number): void {
    const params = {};
    params[PLANS_SHOP_ROUTE_URLS.PARAMS.SUBSCRIPTION_CYCLE] = months;
    this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.NEW_PLAN}`, params]);
  }
  private checkBannerRemovalTime(): void {   
    this.currentTime = new Date();
    if (this.currentTime >= this.targetTime) {
      this.showMainBanner = true;
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
      }
      this.changeDetector.detectChanges();
    } else {
      this.showMainBanner = false;
    }
  }
}
