import { PlatformLocation } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalHelperService } from '@services/modal-helper.service';
import { AnalyticsService } from 'src/services/analytics.service';
import { ActionsAnalyticsService, FirebaseMobilePlansCartService, FirebaseUserProfileService, IUser, IUserPlan } from '@ztarmobile/zwp-service-backend';
import { NewsletterService } from '@ztarmobile/zwp-service-backend-v2';
import { AuthHelperService, UserAuthService } from '@ztarmobile/zwp-services-auth';
import { Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { CAPTCHA_BOT_ERROR_CODE, EMAIL_PATTERN, NAME_PATTERN } from 'src/app/app.config';
import { LOGIN_ROUTE_URLS, SHOP_ROUTE_URLS } from 'src/app/app.routes.names';
import { AppState } from 'src/app/app.service';
import { PASSWORD_PATTERN } from 'src/environments/environment';
import { ToastrHelperService } from 'src/services/toast-helper.service';

export class CheckoutLoginModalContext {
  public title: string;
  public hasCloseLink?: boolean;
  public customClass?: string;
  public notBeforeSkipping?: boolean;
}

@Component({
  selector: 'app-checkout-login',
  templateUrl: './checkout-login.component.html',
  styleUrls: ['./checkout-login.component.scss']
})
export class CheckoutLoginComponent implements OnInit, OnDestroy {
  @ViewChild('loginForm') loginForm: NgForm;
  public context: any;
  public showSignupSection = false;
  public email = '';
  public password = '';
  public emailPattern = EMAIL_PATTERN;
  public userForm: FormGroup;
  public signUppassword = '';
  public confirmPassword = '';
  public user: IUser = {} as IUser;
  public showPassword = false;
  public signUpSubscription: Subscription;
  public newsletterSubscription: Subscription;

  private userCart: IUserPlan;
  referredCode: string;
  private invisibleCaptchaRes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialogRef<CheckoutLoginModalContext>, private location: PlatformLocation,
              private router: Router, private toastHelper: ToastrHelperService, private appState: AppState, private angularAuthService: AngularFireAuth,
              private analyticsService: ActionsAnalyticsService,  private userProfileService: FirebaseUserProfileService, private formBuilder: FormBuilder,
              private userAuthService: UserAuthService, private firebaseMobilePlansCartService: FirebaseMobilePlansCartService,
              private newsLettersService: NewsletterService, private modalHelper: ModalHelperService, private analytics: AnalyticsService) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data;
    this.referredCode = sessionStorage.getItem('referralCode');
    this.userForm = formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, Validators.pattern(NAME_PATTERN)])],
      lastName: ['', Validators.compose([Validators.required, Validators.pattern(NAME_PATTERN)])],
      marketingEmails: [''],
      email: ['', [Validators.required, Validators.pattern(EMAIL_PATTERN)]],
      password: ['', Validators.compose([Validators.required, Validators.minLength(6),
        Validators.maxLength(12), Validators.pattern(PASSWORD_PATTERN)])],
      confirmPassword: ['', Validators.required],
    }, {validator: this.matchingPasswords('password', 'confirmPassword')});
   }

  ngOnInit(): void {
    this.firebaseMobilePlansCartService.userCart.pipe(take(1)).subscribe((cart) => this.userCart = cart);
  }
  ngOnDestroy(): void {
    if (this.signUpSubscription) {
      this.signUpSubscription.unsubscribe();
    }
    if (this.newsletterSubscription) {
      this.newsletterSubscription.unsubscribe();
    }
  }

  public goToForgotPassword(): void {
    const params = [];
    if (!!this.email) {
      params[LOGIN_ROUTE_URLS.PARAMS.EMAIL] = this.email;
    }
    this.closeDialog();
    this.router.navigate([`${LOGIN_ROUTE_URLS.BASE}/${LOGIN_ROUTE_URLS.FORGOT_PASSWORD}`, params]);
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
        document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
      return this.beforeClose();
  }

  closeDialog(): void {
      this.beforeDismiss();
      this.dialog.close();
  }

  public showSignUp(): void {
    this.showSignupSection = true
  }
  public togglePasswordVisibility(id: string): void {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById(id) as HTMLInputElement;
    if (passwordInput) {
      passwordInput.type = this.showPassword ? 'text' : 'password';
    }
  }

  public callIntializeKount(): void {
    this.appState.initializeKountSdk();
  }

  public login(): void {
    this.loginForm.form.markAllAsTouched();
    if (!!this.loginForm.form.valid) {
      this.appState.loading = true;
      this.angularAuthService.signOut().then(() => {
        this.angularAuthService.signInWithEmailAndPassword(this.email, this.password).then((userCred) => {
          this.appState.userLoggedIn.next(true);
          const user = userCred.user;
          this.analytics.trackLogin(user.uid);
          this.analyticsService.sendUserIdToHotjar(user.uid);
          this.callIntializeKount();
            setTimeout(() => {
              if (!!this.referredCode) {
              this.userProfileService.userProfileObservable.pipe(take(1)).subscribe((data) => {
                if (!!data) {
                  data.referredWithCode = this.referredCode;
                  this.userProfileService.bffUpdateUserProfile(data);
                }
              });
            }
            this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECKOUT}`]);
            }, 1000);
              this.dialog.close(true);
        }).catch((error) => {
          this.appState.loading = false;
          if (error.message.includes('wrong-password')) {
            this.toastHelper.showAlert('The password is invalid or the user does not have a password.');
          } else if (error.message.includes('user-not-found')) {
            this.toastHelper.showAlert('There is no user record corresponding to this identifier. The user may have been deleted.');
          } else if (error.message.includes('too-many-requests')) {
            this.toastHelper.showAlert('Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.');
          } else {
            this.toastHelper.showAlert(error.message);
          }
          this.moveCartFromAnonymousToNewAnonymous().then(() => {
          });
        });
      }).catch((error) => {
        this.appState.loading = false;
        this.toastHelper.showAlert(error.message);
      });
    }
  }
  public signUp(): void {
    this.userForm.markAllAsTouched();
    const action = 'g2g_signup';
      if (!!this.userForm.valid ) {
        this.appState.loading = true;
        this.appState.resetAndExecuteCaptchaSubject.next({action});
        this.signUpSubscription= this.appState.resolvedCaptcha.subscribe(response => {
          this.invisibleCaptchaRes = response;
        if(!!this.invisibleCaptchaRes) {
          this.signUpSubscription?.unsubscribe();
          this.callSignupApi(this.invisibleCaptchaRes, true, action);
        }
      });
      }
  
  }
  public callSignupApi(captchaRes, invisibleCaptcha, action?): void {
    this.user.firstName = this.userForm.get('firstName').value;
    this.user.lastName = this.userForm.get('lastName').value;
    this.user.email = this.userForm.get('email').value;
    this.signUppassword = this.userForm.get('password').value;
    this.user.password = this.userForm.get('password').value;
    const subscribeToMarketingEmails = this.userForm.get('marketingEmails')?.value;
    if(!!this.referredCode) {
      this.user.referredWithCode = this.referredCode;
    }
    this.userAuthService.signUpWithBff(captchaRes, this.user, this.signUppassword, !!action?action:null, invisibleCaptcha).then((user) => {
      setTimeout(() => {
        this.angularAuthService.signOut().then(() => {
          this.angularAuthService.signInWithEmailAndPassword(this.user.email, this.signUppassword).then((userCred) => {
            if(!!subscribeToMarketingEmails) {
              this.subscribeToNewsletter();
            }
            this.appState.loading  = false;
            this.appState.userLoggedIn.next(true);
            this.callIntializeKount();
            this.analytics.trackSignup(userCred.user.uid);
            this.analyticsService.sendUserIdToHotjar(userCred.user.uid);
            this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CHECKOUT}`]);
            this.dialog.close(true);
          }, (error) => {
            this.toastHelper.showAlert(error.message);
          });
        });
      }, 1000);
    }, (error) => {
      this.appState.loading  = false;
      this.moveCartFromAnonymousToNewAnonymous().then(() => {
      });
      const errorMessageAsJson = AuthHelperService.getErrorMessage(error);
      if(error?.code === CAPTCHA_BOT_ERROR_CODE) {
        this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result)=> {
          if(!!result) {
            this.appState.loading = true;
            this.callSignupApi(result, false);
          }
        });
       } else {
        this.toastHelper.showAlert(errorMessageAsJson.message || 'Sign Up failed');
       }
      this.signUpSubscription?.unsubscribe();
    });
  }
  public subscribeToNewsletter(): void {
    const action = 'g2g_email_preference_subscription';
    this.appState.loading = true;
    this.appState.resetAndExecuteSecondCaptchaSubject.next({action});
    setTimeout(() => {
    this.newsletterSubscription= this.appState.resolvedSecondCaptcha.subscribe(response => {
    this.invisibleCaptchaRes = response;
    if(!!this.invisibleCaptchaRes) {
         this.newsletterSubscription?.unsubscribe();
         this.callNewsletterApi(this.invisibleCaptchaRes,true, action);
    }
    });
    }, 500);
  }
  public callNewsletterApi(recaptchaRes, invisibleCaptcha, action?): void {
    this.newsLettersService.subscribe({ email: this.userForm.get('email').value }, 'email-preference',recaptchaRes, !!action? action:null , invisibleCaptcha).then((res) => {
      this.appState.loading = false;
      this.userProfileService.userProfileObservable.pipe(take(1),filter((user) => !!user)).subscribe((user) => {
        if(!!user && !!user?.email) {
          user.receiveMarketingMails = true;
          this.userProfileService.bffUpdateUserProfile(user);
        }
      });
    }, (error) => {
      this.appState.loading = false;
      if(error?.error?.errors[0]?.code === CAPTCHA_BOT_ERROR_CODE) {
        this.modalHelper.showRecaptchaVerificationModal().afterClosed().subscribe((result)=> {
          if(!!result) {
            this.appState.loading = true;
            this.callNewsletterApi(result, false);
          }
        });
       } else {
        this.toastHelper.showWarning(`Account successfully created! Please refer to the site's footer to resubmit your marketing emails preference.`);
       }
      this.newsletterSubscription?.unsubscribe();
    });
  }
  private matchingPasswords(passwordKey: string, confirmPasswordKey: string): any {
    return (group: FormGroup): { [key: string]: any } => {
      const password = group.controls[passwordKey];
      const confirmPassword = group.controls[confirmPasswordKey];

      if (password.value !== confirmPassword.value) {
        return {
          mismatchedPasswords: true
        };
      }
    };
  }
  private moveCartFromAnonymousToNewAnonymous(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.firebaseMobilePlansCartService.clearUserCart().then(() => {
        this.userAuthService.loginAnonymous(true).then(() => {
          this.firebaseMobilePlansCartService.updateUserCart(this.userCart).then(() => resolve(), (error) => reject(error));
        });
      });
    });
  }
}
