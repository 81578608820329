import { PlatformLocation } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';

export class ErrorMessageModalContext {
  public title: string;
  public message: string;
  public hasCloseLink: boolean;
  public displayErrorIcon: boolean;
  public btnText: string;
  public secondaryText?:string;
  public customClass?: string;
  public customHTML?: string;
}

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  public context: any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialogRef<ErrorMessageModalContext>, private location: PlatformLocation) {
    location.onPopState(() => {this.beforeDismiss();this.dialog.close();});
    this.context = data; }

  ngOnInit(): void {
  }

  beforeClose(): boolean {
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
    return false;
  }

  beforeDismiss(): boolean {
    return this.beforeClose();
  }

  closeDialog(res?): void {
    this.beforeDismiss();
    this.dialog.close(res);
  }

}
