<div class="modal">
  <div class="container model-container {{context.customClass}}">
    <div class="modal-header">
      <h2 class="modal-heading">{{context.title}}</h2>
      <button class="modal-close modal-toggle" (click)="closeDialog()"><i class="icon-close"></i></button>
    </div>
    <div class="modal-body">
      <form #confirmPasswordForm="ngForm">
        <div class="modal-content">
          <p>
            {{context.message}}
          </p>
          <fieldset>
            <label class="outlined-input" [ngClass]="{'show-eye': !!confirmCurrentPassword, 'input-error': !confirmPasswordForm.valid && confirmPasswordForm.touched}">
              <input id="confirmCurrentPassword" data-cy="confirmCurrentPassword" [type]="showPassword ? 'text' : 'password'"
                name="confirmCurrentPassword" [(ngModel)]="confirmCurrentPassword" #currentPassword required
                autocomplete="current-password" placeholder=" ">
              <span>Current Password</span>
            </label>
            <img class="eye-icon" (click)="togglePassword()" *ngIf="!!confirmCurrentPassword" src="assets/img/grey-eye.svg" alt="eye" loading="lazy" height="24" width="24">
            <label class="validation-message" *ngIf="!confirmPasswordForm.valid && confirmPasswordForm.touched">Password is required</label>
          </fieldset>
          <div class="modal-actions">
            <button class="button secondary" (click)="closeDialog()">Cancel</button>
            <button class="button primary" [disabled]="!confirmPasswordForm.valid" (click)="submitPassword()">Verify</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
