import { Component, Inject, OnInit } from '@angular/core';
import { IUser } from '@ztarmobile/zwp-services-auth';
import { take } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AccountPaymentService,
  FirebaseAccountPaymentService,
  ICreditCardInfo,
  IFirebasePaymentMethod,
  IUserPlan,
  UserPlansService
} from '@ztarmobile/zwp-service-backend';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ToastrHelperService } from 'src/services/toast-helper.service';
import { PlatformLocation } from '@angular/common';
import { ACCOUNT_ROUTE_URLS } from '@app/app.routes.names';
import { AccountHeaderService } from '@app/account/account-header.service';
import { AppState } from '@app/app.service';
import { UserDataService } from '@services/user-data.service';

export class G2gPaymentModalContext {
  public paymentMethodId: string;
  public userPlan: IUserPlan;
  public user: IUser;
  public customClass?: string;
  public accountSettingsRoute?: string;
}

@Component({
  selector: 'app-manage-payment-specific-modal',
  templateUrl: './manage-payment-specific-modal.component.html'
})
export class ManagePaymentSpecificModalComponent implements OnInit {
  public context: any;
  public paymentInfo: ICreditCardInfo;
  public processingRequest: boolean;
  public setDefaultRequest: boolean;
  public methodsList: IFirebasePaymentMethod[] = [];
  public expiredMethodsList: IFirebasePaymentMethod[] = [];
  public nonExpiredmethodsList: Array<IFirebasePaymentMethod> = [];
  public editMode: boolean;
  public selectedMethodId: string;
  public currentYear;
  public currentMonth;

  private paymentMethodsListSubscription: Subscription;
  private currentDate: Date;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private firebaseAccountPaymentService: FirebaseAccountPaymentService,
    private toastHelper: ToastrHelperService,
    private accountPaymentService: AccountPaymentService,
    private userPlanService: UserPlansService,
    private router: Router,
    public dialog: MatDialogRef<G2gPaymentModalContext>,
    private location: PlatformLocation,
    private accountHeaderService: AccountHeaderService,
    private appState: AppState,
    private userDataService: UserDataService) {
    this.editMode = false;
    location.onPopState(() => { this.beforeClose(); this.dialog.close(); });
    this.context = data;
  }

  ngOnInit(): void {
    this.currentDate = new Date();
    // we have to add 1 since getMonth start array from index 0
    this.currentMonth = this.currentDate.getMonth() + 1;
    this.currentYear = this.currentDate.getFullYear();
    this.userPlanService.getUserPlan(this.context?.userPlan?.id).then((userPlan) => {
      this.context.userPlan = userPlan;
      this.selectedMethodId = this.context?.paymentMethodId;
    });

    this.paymentMethodsListSubscription = this.firebaseAccountPaymentService.paymentMethodsList.pipe(take(1)).subscribe((methods) => {
      this.methodsList = methods;
      if (this.methodsList?.length > 0) {
        this.currentMonth = this.currentMonth.toString();
        this.currentYear = this.currentYear.toString().slice(-2);
        if (this.currentMonth?.length === 1) {
          this.currentMonth = `0${this.currentMonth}`;
        }
        this.methodsList.map(method => {
          if (!!method && !!method?.expirationDate) {
            let cardExpMonth = method?.expirationDate?.substr(0, 2);
            let cardExpYear = method?.expirationDate?.substr(2, 2);
            // if card year less than currect year then its expired
            if (parseInt(cardExpYear, 10) < parseInt(this.currentYear, 10)) {
              this.expiredMethodsList.push(method);
              Object.assign(method, { expired: true });
            } else if (parseInt(cardExpYear, 10) <= parseInt(this.currentYear, 10)) {
              if (parseInt(cardExpMonth, 10) < parseInt(this.currentMonth, 10)) {
                this.expiredMethodsList.push(method);
                Object.assign(method, { expired: true });
              }
            } else {
              Object.assign(method, { expired: false });
              this.nonExpiredmethodsList.push(method);
            }
          }
        });
        this.fillPaymentInfoForm();
      }
    });
  }

  beforeClose(): void {
    if (!!this.paymentMethodsListSubscription) {
      this.paymentMethodsListSubscription.unsubscribe();
    }
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open');
    }
  }
  public closeDialog(methodId?: string): void {
    this.beforeClose();
    this.dialog.close(methodId);
  }
  public makePaymentMethodAsDefault(methodKey): void {
    if (this.setDefaultRequest) {
      return;
    }
    const userPlanId = !!this.context.userPlan ? this.context.userPlan.id : undefined;
    this.setDefaultRequest = true;
    this.appState.loading = true;
    this.accountPaymentService.setPaymentMethodAsDefault(methodKey, userPlanId).then(() => {
      setTimeout(() => {
        this.userDataService.callSyncApi(this.context.userPlan.id, this.context.userPlan.mdn).then((data) => {
          this.setDefaultRequest = false;
          this.accountHeaderService.setMdnInfo({plan: this.context.userPlan, account: data, loadingData: false});
          this.appState.loading = false;
          this.toastHelper.showSuccess('Default payment method set, it may take about 1 minute before the account getting the effect.');
          this.closeDialog('success');
        })
     
      }, 200);
    }, (error) => {
      this.setDefaultRequest = false;
      this.appState.loading = false;
      this.toastHelper.showAlert(error.message || error);
      this.closeDialog('error');
    });
  }
  public goToSettings(): void {
    this.closeDialog();
    const params = {};
    params[ACCOUNT_ROUTE_URLS.PARAMS.MANAGE_CARDS] = true;
    this.router.navigate([this.context.accountSettingsRoute, params]);
  }
  public checkBoxLabel(address: IFirebasePaymentMethod): string {
    let addressLabel = '';
    if (!!address.brand) {
      addressLabel = address.brand;
    } else {
      if (!!address.alias) {
        addressLabel = address.alias;
      }
    }
    return addressLabel.toLowerCase();
  }
  public fillPaymentInfoForm(): void {
    if (!!this.context?.paymentMethodId) {
      this.editMode = true;

      const paymentMethodId = (this.context?.paymentMethodId || this.context?.userPlan?.paymentMethodId);
      const paymentMethod = this.methodsList.find((method) => method?.id === paymentMethodId) || {} as IFirebasePaymentMethod;
      this.paymentInfo = {
        id: paymentMethod?.id,
        cardNumber: '',
        cardCode: '',
        fullName: this.context?.user?.fullName,
        email: this.context?.user?.email,
        type: 'creditCard',
        primary: !!this.context?.userPlan ? this.context.userPlan?.paymentMethodId === paymentMethod?.id : false,
        expirationDate: !!paymentMethod?.expirationDate ? paymentMethod.expirationDate : null,
        address1: paymentMethod?.address1,
        address2: paymentMethod?.address2,
        state: paymentMethod?.state,
        country: paymentMethod?.country,
        city: paymentMethod?.city,
        postalCode: paymentMethod?.postalCode
      } as ICreditCardInfo;
    } else {
      this.paymentInfo = {
        fullName: this.context?.user?.fullName,
        email: this.context?.user?.email
      } as ICreditCardInfo;
    }
  }
}
