import { AfterViewInit, Component, HostListener, Input, OnChanges, OnDestroy, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleAuthService } from '@ztarmobile/zwp-services-auth';
import { CART_TYPES, CustomizableMobilePlan, IUserAccount, IUserPlan, MobileCustomPlansService, UserAccountService, UserPlansService, FirebaseUserProfileService, IUser, ICreditCardInfo } from '@ztarmobile/zwp-service-backend';
import { combineLatest, takeWhile } from 'rxjs/operators';
import { ROUTE_URLS, LOGIN_ROUTE_URLS, SHOP_ROUTE_URLS, ACCOUNT_ROUTE_URLS, ACTIVATION_ROUTE_URLS, SUPPORT_ROUTE_URLS, PLANS_SHOP_ROUTE_URLS, PHONES_SHOP_ROUTE_URLS, ACP_ROUTE_URLS } from '../../app/app.routes.names';
import { AppState } from '../../app/app.service';
import { PhonePipe } from '../pipes/phone.pipe';
import { ModalHelperService } from '../../services/modal-helper.service';
import { ContentfulService } from 'src/services/contentful.service';
import { IVerificationRes } from '@ztarmobile/zwp-service-backend-v2';
import { CheckoutService } from 'src/app/shop/checkout/checkout.service';
import { AnalyticsService } from 'src/services/analytics.service';
import { UserAccountService  as accountService} from '@ztarmobile/zwp-service-backend-v2';
import { AccountHeaderService } from '@app/account/account-header.service';
@Component({
  selector: 'app-header-main-navbar',
  templateUrl: './header-main-navbar.component.html',
  styleUrls: ['./header-main-navbar.component.scss']
})
export class HeaderMainNavbarComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() pageUrl: string;
  @Input() mdnData: any;
  public isLoggedIn = false;
  public hasCartItem = true;
  public totalItems = 0;
  public activeItem = 0;
  public userCart: CustomizableMobilePlan;
  public userAccounts: IUserAccount[];
  public loadingPlan = false;
  public selectedPlan: IUserPlan;
  public notificationCounter = 0;
  public isExpiredAccount = false;
  public isPortIn = false;
  public ROUTE_URLS = ROUTE_URLS;
  public LOGIN_ROUTE_URLS = LOGIN_ROUTE_URLS;
  public SHOP_ROUTE_URLS = SHOP_ROUTE_URLS;
  public ACCOUNT_ROUTE_URLS = ACCOUNT_ROUTE_URLS;
  public ACTIVATION_ROUTE_URLS = ACTIVATION_ROUTE_URLS;
  public SUPPORT_ROUTE_URLS = SUPPORT_ROUTE_URLS;
  public PLANS_SHOP_ROUTE_URLS = PLANS_SHOP_ROUTE_URLS;
  public PHONES_SHOP_ROUTE_URLS = PHONES_SHOP_ROUTE_URLS;
  public userHasActivePlans = false;
  public userPlans: IUserPlan[];
  public innerWidth;
  public displayMaintenanceBanner = false;
  public mantainenceDescription;
  public isEbbPlan = false;
  public userProfile: IUser;
  public displayAcpSection = false;
  public showACPActionBanner = false;
  public acpActionRequired = false;
  public ACP_STATUS = {
    COMPLETE: 'COMPLETE',
    PENDING_RESOLUTION: 'PENDING_RESOLUTION',
    IN_PROGRESS: 'IN_PROGRESS',
    PENDING_REVIEW: 'PENDING_REVIEW',
    PENDING_CERT: 'PENDING_CERT'
  };
  public acpPlan: IUserPlan;
  public showActivatePlanContent = false;
  public showResumeFilingContent = false;
  public isActivatedAcpPlan = false;
  public verificationDetails: IVerificationRes;
  public subMenu;
  public showMdnList = true;
  public isMoreDetails = false;
  public isCloseBanner = false;
  public isOnIosLandingPage = false;
  public showOutage= false;
  public showACPNotice = false;
  public syncError: string;
  private showActivatePlanContentCopy = false;
  private showResumeFilingContentCopy = false;
  private timeout: any;
  private headerHeight;
  private alertBannerHeight;
  private acpAlertsHeight;
  private alive = false;
  private pendingPlans: IUserPlan[];
  showMenu: boolean;
  isCloseAcpNoticeBanner: any;

  constructor(
    private renderer: Renderer2,
    private simpleAuthService: SimpleAuthService,
    public router: Router,
    private userPlansService: UserPlansService,
    private route: ActivatedRoute,
    private checkoutService: CheckoutService,
    private appState: AppState,
    private angularAuthService: AngularFireAuth,
    private mobilePlansService: MobileCustomPlansService,
    private userAccountService: UserAccountService,
    private modalHelper: ModalHelperService,
    private analyticService: AnalyticsService,
    private contentfulService: ContentfulService,
    private userProfileService: FirebaseUserProfileService,
    private userAccountServicev2: accountService,
    private accountHeaderService: AccountHeaderService
  ) {
  }

  ngOnInit(): void {
    this.isCloseBanner = JSON.parse(sessionStorage.getItem('closeIosBanner'));
    this.isCloseAcpNoticeBanner = JSON.parse(sessionStorage.getItem('closeNoticeBanner'));
    this.mobilePlansService.currentPlan.subscribe((plan) => {
      this.hasCartItem = (!!plan && plan.hasPlanItem) || (!!plan && plan.simsQuantity > 0) || (!!plan && !!plan.addOns) || (!!plan && !!plan.phones);
      this.calculateCartItems(plan);
      this.getNotificationCount();
      if (!!plan && !!plan.basePlan && !!plan.basePlan.ebb) {
        this.isEbbPlan = true;
        this.totalItems = 0;
      } else {
        this.isEbbPlan = false;
      }
    });
    this.simpleAuthService.userState.subscribe((authState) => {
      this.isLoggedIn = !!authState && !authState.isAnonymous;
      // if (!this.isLoggedIn) {
      //   this.showActivatePlanContent = false;
      //   this.showResumeFilingContent = false;
      //   this.showActivatePlanContentCopy = false;
      //   this.showResumeFilingContentCopy = false;
      // }
    });
   
    this.userProfileService.userProfileObservable.subscribe((user) => {
      this.userProfile = user;
      if (!!user) {

        this.userPlansService.userPlans.subscribe((plans) => {
          if (!!plans) {
            this.pendingPlans = plans.filter((plan) => !plan.mdn);
            if (!this.mdnData) { // if the user didn't go direct to account pages then the mdn data will be null and we have to set the selected plan value
              const activePlans =  plans.filter((plan) => !!plan.mdn);
              if (!!activePlans && activePlans.length > 0) {
                this.selectedPlan = activePlans[0];
                // adding timeout until the url is detect correctly 
               setTimeout(() => {
                this.userPlanSelected(activePlans[0]);
              },2000)
              }
            }
          }
        });
      }
    });

    this.getMaintenanceContentFromContentful();
    this.innerWidth = window.innerWidth;
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  ngAfterViewInit(): void {
    const header = document.getElementById('header');
    this.headerHeight = !!header && !!this.showACPActionBanner ? header.clientHeight : (window.innerWidth >= 640 ? 64 : 52);
    this.appState.globalAlertHeightReplySubject.next(this.headerHeight);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.pageUrl) {
      this.pageUrl = changes?.pageUrl?.currentValue;
      // this is to handle hiding the banner after navigating to the acp application page
      this.isOnIosLandingPage = this.pageUrl?.includes(SUPPORT_ROUTE_URLS.IOS17);
      this.showACPNotice = this.isActivatedAcpPlan && this.pageUrl.indexOf(ACCOUNT_ROUTE_URLS.ACP_APPLICATION) < 0 && !this.isCloseAcpNoticeBanner && !!this.isLoggedIn;
      if (!!this.pageUrl && (this.pageUrl.indexOf(ACCOUNT_ROUTE_URLS.ACP_APPLICATION) > -1 || this.pageUrl.indexOf(ACP_ROUTE_URLS.BASE) > -1)) {
        this.showACPActionBanner = false;
        this.showActivatePlanContent = false;
        this.showResumeFilingContent = false;
        this.appState.globalAlertHeightReplySubject.next(this.headerHeight);
      } else if (!!this.pageUrl && this.pageUrl.indexOf(ACTIVATION_ROUTE_URLS.CHOOSE_ACTIVATION_PATH) > -1 && !!this.acpPlan && this.pageUrl.indexOf(this.acpPlan.id) > -1) {
        this.showActivatePlanContent = false;
        this.showResumeFilingContent = false;
        this.appState.globalAlertHeightReplySubject.next(this.headerHeight);
      } else {
        this.showACPActionBanner = this.acpActionRequired;
        this.showActivatePlanContent = this.showActivatePlanContentCopy;
        this.showResumeFilingContent = this.showResumeFilingContentCopy;
        if (!!this.showResumeFilingContent || !!this.showActivatePlanContent) {
          setTimeout(() => {
            const alert = document.getElementById('acp-alert');
            if (!!alert) {
              this.acpAlertsHeight = alert?.clientHeight;
              this.appState.globalAlertHeightReplySubject.next(this.headerHeight + this.acpAlertsHeight);
            }
          }, 200);
        }
        else if (!!this.showACPActionBanner) {
          setTimeout(() => {
            const alert = document.getElementById('alert');
            this.alertBannerHeight = alert?.clientHeight;
            this.appState.globalAlertHeightReplySubject.next(this.headerHeight + this.alertBannerHeight);
          }, 200);
        } else {
          this.appState.globalAlertHeightReplySubject.next(this.headerHeight);
        }
      }
    }
    if (!!changes.mdnData && !!changes.mdnData.currentValue) {
      this.selectedPlan = changes.mdnData.currentValue.plan;
      this.loadingPlan = !!changes.mdnData.currentValue.loadingData ? changes.mdnData.currentValue.loadingData : false;
      this.syncError = !!changes.mdnData.currentValue.error ? changes.mdnData.currentValue.error : null;
    }
  }

  public closeBanner(): void {
    this.isCloseBanner = true;
    sessionStorage.setItem('closeIosBanner', JSON.stringify(this.isCloseBanner));
  }

  // public closeAcpNoticeBanner(): void {
  //   this.isCloseAcpNoticeBanner = true;
  //   this.showACPNotice = false;
  //   sessionStorage.setItem('closeNoticeBanner', JSON.stringify(this.isCloseAcpNoticeBanner));
  // }
  public goToAddOns(): void {
    if (!this.isExpiredAccount && !this.isPortIn) {
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PLAN_ADD_ONS}`]);
    }
  }

  public changePlan(): void {
    if (!this.isPortIn && !this.selectedPlan.canceled) {
      if (!!this.userCart && !!this.userCart.cartType && this.userCart.cartType !== CART_TYPES.CHANGE_PLAN) {
        // eslint-disable-next-line max-len
        this.modalHelper.showConfirmMessageModal('Clear Cart', 'Purchasing a plan will remove any other item in your cart. Do you want to proceed?', 'Yes', 'No', 'clean-cart-modal')
          .afterClosed().subscribe((result) => {
            if (result) {
              this.mobilePlansService.clearUserCart();
              this.appState.clearSessionStorage();
              const removedItems = this.analyticService.prepareItems(this.userCart.cartType, {plan: !!this.userCart.basePlan && this.userCart.basePlan.id !== 'NO-PLAN' ? this.userCart.basePlan : null, sim: !this.userCart.activationCode ? this.userCart.planDevice : null, phone: !!this.userCart.phones && this.userCart.phones.length > 0 ? this.userCart.phones[0]: null, addons: this.userCart.addOns});
              this.analyticService.trackRemoveFromCart(removedItems);
              const params = {};
              params[ROUTE_URLS.PARAMS.USER_PLAN_ID] = this.selectedPlan.id;
              this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.CHANGE_PLAN}`, params]);
            }
          }, (error) => {
            console.error('error', error);
          });
      } else {
        const params = {};
        params[ROUTE_URLS.PARAMS.USER_PLAN_ID] = this.selectedPlan.id;
        this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.PLANS_AND_FEATURES}/${PLANS_SHOP_ROUTE_URLS.CHANGE_PLAN}`, params]);
      }

    }
  }

  public activatePlan(): void {
    if (!!this.acpPlan) {
      const params = {};
      params[ROUTE_URLS.PARAMS.USER_PLAN_ID] = this.acpPlan.id;
      if (!!this.acpPlan && !!this.acpPlan.planDevice && !!this.acpPlan.planDevice.id) {
        this.router.navigate([`${ACTIVATION_ROUTE_URLS.BASE}/${ACTIVATION_ROUTE_URLS.CHOOSE_ACTIVATION_PATH}`, params]);
      } else {
        this.router.navigate([`${ACTIVATION_ROUTE_URLS.BASE}/${ACTIVATION_ROUTE_URLS.CHECK_PHONE}`, params]);
      }
    }
  }

  public goToAcp(): void {
    window.open(`${this.verificationDetails?.link}`, '_self');
  }

  public logout(event): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
    this.router.navigate([ROUTE_URLS.HOME]);
    this.appState.clearSessionStorage();
    this.angularAuthService.signOut();
    this.appState.userLoggedIn.next(undefined);
    this.acpActionRequired = false;
    this.showACPActionBanner = false;
    this.showACPNotice = false;
    this.acpPlan = null;
    this.isActivatedAcpPlan = false;
    this.appState.globalAlertHeightReplySubject.next(this.headerHeight);
    this.appState.selectedMdnDetails.next(null);
    sessionStorage.removeItem('sessionId');
    this.clearAccountStorage();
    this.checkoutService.setPayments({ card: { address1: '', address2: '', cardCode: '', cardNumber: '', last4: '', id: '', city: '', state: '', country: '', postalCode: '', method: '', name: '', alias: '', fullName: '', brand: '' } });
    this.accountHeaderService.setMdnInfo(null);
  }

  public getPriority(): void {
    if (!this.isLoggedIn) {
      const params = {};
      params[LOGIN_ROUTE_URLS.PARAMS.NEXT_PAGE] = `${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.SUMMARY}`;
      this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.SUMMARY}`]);
    } else {
      if (!!this.userAccounts && this.userAccounts.length > 0) {
        this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PAY_AND_RENEW}`]);
      } else if (!!this.pendingPlans && this.pendingPlans?.length > 0) {
        this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.PENDING_ACTIVATIONS}`]);
      }
    }
  }

  public toggleMenu(): void {
    if (!!this.showMenu) {
      this.allowScrolling()
    } else {
      this.renderer.addClass(document.body, 'modal-open');
      this.renderer.removeClass(document.body, 'modal-closed');
    }
    this.showMenu = !this.showMenu;
  }

  public getSelectorTitle(plan: IUserPlan): string {
    const mdn: string = (new PhonePipe()).transform(plan.mdn);
    const title = !!plan.portInRequestNumber ? `PortIn for ${mdn}` : (!!plan.canceled ? `${mdn} - Canceled` : mdn);
    return title;
  }

  public planCompare(plan1: IUserPlan, plan2: IUserPlan) {
    return !!plan1 && !!plan2 ? plan1.id === plan2.id : plan1 === plan2;
  }

  public userPlanSelected(userPlan: IUserPlan): void {
    this.appState.selectedMdnDetails.next({plan: userPlan});
    const decodedUrl = decodeURIComponent(this.router.url);
    if (!!userPlan && !userPlan.canceled && !userPlan.portInRequestNumber && decodedUrl.indexOf(ACCOUNT_ROUTE_URLS.BASE) < 0) { // if the user is not already in the account page then the sync from activated selector will not be called so I am checking here the url and call sync based on that
      this.loadingPlan = true;
      this.userAccountServicev2.getMdnDetails(this.selectedPlan.id, this.selectedPlan.mdn, 'MDNSIMPLE').then((response) => {
        if (!!response && response.mdn === userPlan.mdn) { // this is added because the user will be able to change the mdn while the other sync is being called and it can show the first mdn details for the new selected one without this condition
          this.loadingPlan = false;
          this.selectedPlan = userPlan;
        }
       
      }, (error) => {
        if (!!error) {
          const errorCode = error.error.errors[0].code;
          this.loadingPlan = false;
          this.syncError = error.error.errors[0];
        }
      })
    }
  }

  public showSubMenu(menu): void {
    this.subMenu = menu === this.subMenu ? null : menu;
  }
  public allowScrolling(): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
  }

  public goToCart(): void {
    if (this.hasCartItem && !(this.router.url.indexOf('cart') > -1) && !this.isEbbPlan) {
      this.showMenu = false;
      this.router.navigate([`${SHOP_ROUTE_URLS.BASE}/${SHOP_ROUTE_URLS.CART}`]);
    }
  }
  public resetTimeout(): void {
    if (!!this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  public calculateCartItems(plan): void {
    this.totalItems = 0;
    if (!!plan) {
      if (!!plan.cartType && plan.cartType !== CART_TYPES.PLAN_ITEMS) {
        this.totalItems = !this.isEbbPlan ? 1 : 0;
      } else {
        if (plan.addOns) {
          this.totalItems += plan.addOns.length;
        }
        if (plan.simsQuantity) {
          this.totalItems += 1;
        }
      }
    }
  }

  public getNotificationCount(): void {
    if (!!this.isLoggedIn) {
      this.userAccountService.userAccounts.pipe(combineLatest(this.userPlansService.userPlans, (accounts: IUserAccount[], plans) => {
        const userAccounts = accounts.filter((account) => account.billingRenewDaysLeft <= 3);
        let pendingPlans: IUserPlan[];
        let activePlans: IUserPlan[];
        let duePlan: IUserPlan;
        if (!!plans) {
          pendingPlans = plans.filter((plan) => !plan.mdn);
          activePlans = plans.filter((plan) => !!plan.mdn);
          this.userPlans = activePlans;
          if (userAccounts.length > 0) {
            this.notificationCounter = 1;
            duePlan = this.userPlans.find((plan) => plan.mdn === userAccounts[0].mdn);
          } else {
            this.notificationCounter = pendingPlans.length;
          }
          this.userHasActivePlans = (!!activePlans && activePlans.length > 0);
        }
      }), takeWhile(() => this.alive)).subscribe();
    }
  }

  private getMaintenanceContentFromContentful(): void {
    this.contentfulService.getContent('maintenanceModel').subscribe(contents => {
      if (!!contents) {
        const result = contents[0].fields;
        this.mantainenceDescription = this.contentfulService.getRichTextWithOptions(result.maintenanceDescription);
        const maxDate = new Date(result.endDisplayDate);
        const displayDate = new Date(result.maintenanceDisplayDate);
        const todayDate = new Date();
        if (todayDate < maxDate && todayDate >= displayDate) {
          this.displayMaintenanceBanner = true;
        }
      }

    });
  }
  private clearAccountStorage(): void {
    sessionStorage.removeItem('nextPaymentPaid');
    sessionStorage.removeItem('futureDetails');
    sessionStorage.removeItem('setupeSim');
    sessionStorage.removeItem('setupPlan');
    sessionStorage.removeItem('actionRequired');
    sessionStorage.removeItem('swapeSIM');
    sessionStorage.removeItem('replacementSim');
    sessionStorage.removeItem('revertCancellation');
    sessionStorage.removeItem('portInBanner');
    sessionStorage.removeItem('trialBanner');
    sessionStorage.removeItem('upgradeBanner');
  }
  // public goToACPApplication(): void {
  //   this.router.navigate([`${ACCOUNT_ROUTE_URLS.BASE}/${ACCOUNT_ROUTE_URLS.ACP_APPLICATION}`]);
  // }
  // public goToPaymentUpdate(): void {
  //   this.router.navigate(['/' + DUPLICATE_PAYMENTS_ROUTE_URLS.BASE + '/' + DUPLICATE_PAYMENTS_ROUTE_URLS.PAYMENT_ATTENTION]);
  // }

  // public continueEditing(): void {
  //   const params = {};
  //   const paymentUpdateInProgressSaved = sessionStorage.getItem('attention-payment');
  //   params[DUPLICATE_PAYMENTS_ROUTE_URLS.PARAMS.ID] = paymentUpdateInProgressSaved;
  //   this.router.navigate(['/' + DUPLICATE_PAYMENTS_ROUTE_URLS.BASE + '/' + DUPLICATE_PAYMENTS_ROUTE_URLS.CHANGE_PREFERRED_PAYMENT, params]);
  // }
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = window.innerWidth;
    const header = document.getElementById('header');
    this.headerHeight = !!header && !!this.showACPActionBanner ? header.clientHeight : (window.innerWidth >= 640 ? 64 : 52);
    if (this.showACPActionBanner) {
      const alert = document.getElementById('alert');
      this.alertBannerHeight = alert?.clientHeight;
      this.appState.globalAlertHeightReplySubject.next(this.headerHeight + this.alertBannerHeight);
    } else {
        this.appState.globalAlertHeightReplySubject.next(this.headerHeight);
    }
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.renderer.addClass(document.body, 'modal-closed');
  }
}
