<div class="modal">
    <div class="container model-container {{context.customClass}}">
      <div class="modal-header">
        <div class="img-container" *ngIf="!!context?.displayErrorIcon">
            <img src="assets/icon/alert-info-icon.svg" alt="error">
        </div>
        <h4 class="modal-heading"*ngIf="context?.title">{{context.title}}</h4>
        <button *ngIf="context.hasCloseLink" class="modal-close modal-toggle" (click)="closeDialog(true)"><i
          class="icon-close"></i></button>
      </div>
      <div class="modal-body">
        <div class="modal-content">
            <div class="modal-messages">
              <p class="message" *ngIf="!context.customHTML">
                {{context.message}}
              </p>
              <div *ngIf="!!context.customHTML" [innerHTML]="context.customHTML"></div>
          
            </div>
          <div class="modal-actions">
            <button class="button primary" (click)="closeDialog(true)" id="action-button" data-cy="action-button">{{context.btnText || 'OK'}}</button>
            <button class="button tertiary" (click)="closeDialog(false)" id="secondary-button" *ngIf="!!context?.secondaryText">{{context.secondaryText || 'CANCEL'}}</button>
          </div>
        </div>
      </div>
    </div>
    </div>