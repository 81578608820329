import { Injectable } from '@angular/core';
import { IUserAccount, IUserPlan } from '@ztarmobile/zwp-service-backend';
import { IAccountData } from '@ztarmobile/zwp-service-backend-v2';
import { IOrderDetails } from '@ztarmobile/zwp-service-backend-v2/lib/models/orders/orders';
import { Observable, ReplaySubject } from 'rxjs';

export interface AccountPageDescription {
  getDescription(): string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountHeaderService {
  public pageDescription: Observable<string>;
  public pageTitle: Observable<string>;
  public isMenuVisible: Observable<boolean>;
  public isWelcomeVisible: Observable<boolean>;
  public removePadding: Observable<boolean>;
  public orderInfo: Observable<IOrderDetails>;
  public planSelectorVisible: Observable<boolean>;
  public mdnInfo: Observable<{account?: IUserAccount, plan?: IUserPlan, canceled?: boolean, error?:string, accountStatus?: string, loadingData?: boolean}>;
  public usageData: Observable<{usageData?: IAccountData, error?:string, loadingUsageData?: boolean}>;
  public syncData: Observable<boolean>;
  public loaderDataSubject: ReplaySubject<any> = new ReplaySubject<any>(1);
  public headerPlanData: Observable<IUserPlan>;

  private pageDescriptionSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  private pageTitleSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  private accountMenuVisibleSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private removePaddingSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private orderInfoSubject: ReplaySubject<IOrderDetails> = new ReplaySubject<IOrderDetails>(1);
  private accountWelcomeVisibleSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private planSelectorVisibleSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private mdnInfoSubject: ReplaySubject<{account?: IUserAccount, plan?: IUserPlan, canceled?: boolean, error?:string, accountStatus?: string, loadingData?: boolean}> = new ReplaySubject<{account?: IUserAccount, plan?: IUserPlan, canceled?: boolean, error?:string, accountStatus?: string}>(1);
  private accountUsageDataSubject: ReplaySubject<{usageData?: IAccountData, error?:string, loadingUsageData?: boolean}> = new ReplaySubject<{usageData?: IAccountData, error?:string, loadingUsageData?: boolean}>(1);
  private syncDataSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private headerDataSubject: ReplaySubject<IUserPlan> = new ReplaySubject<IUserPlan>(1);
  

  constructor() {
    this.pageDescription = this.pageDescriptionSubject.asObservable();
    this.isMenuVisible = this.accountMenuVisibleSubject.asObservable();
    this.isWelcomeVisible = this.accountWelcomeVisibleSubject.asObservable();
    this.pageTitle = this.pageTitleSubject.asObservable();
    this.removePadding = this.removePaddingSubject.asObservable();
    this.orderInfo = this.orderInfoSubject.asObservable();
    this.planSelectorVisible = this.planSelectorVisibleSubject.asObservable();
    this.mdnInfo = this.mdnInfoSubject.asObservable();
    this.usageData = this.accountUsageDataSubject.asObservable();
    this.syncData = this.syncDataSubject.asObservable();
    this.headerPlanData = this.headerDataSubject.asObservable();
  }

  public setPageDescription(html: string): void {
    this.pageDescriptionSubject.next(html);
  }

  public setAccountMenuVisibility(isVisible: boolean): void {
    this.accountMenuVisibleSubject.next(isVisible);
  }

  public setAccountWelcomeVisibility(isVisible: boolean): void {
    this.accountWelcomeVisibleSubject.next(isVisible);
  }

  public setPageTitle(title: string): void {
    this.pageTitleSubject.next(title);
  }

  public setRemovePadding(remove: boolean): void {
    this.removePaddingSubject.next(remove);
  }

  public setOrderInfo(order: IOrderDetails): void {
    this.orderInfoSubject.next(order);
  }
  public setPlanSelectorVisibility(isVisible: boolean): void {
    this.planSelectorVisibleSubject.next(isVisible);
  }
  public setMdnInfo(details): void {
    this.mdnInfoSubject.next(details);
  }
  public setAccountUsageInfo(details): void {
    this.accountUsageDataSubject.next(details);
  }
  public setSyncData(sync): void {
    this.syncDataSubject.next(sync);
  }
  public setHeaderPlan(plan): void {
    this.headerDataSubject.next(plan);
  }
  public setLoaderData(data): void {
    this.loaderDataSubject.next(data);
  }
}
