<div class="modal">
<div class="container model-container {{settings.customClass || ''}}">
  <div class="modal-header">
    <h4 class="modal-heading">{{settings.title || ''}}</h4>
    <button id="close-popup-btn" data-cy="closeIcon" class="modal-close modal-toggle" (click)="closeDialog()"><i
        class="icon-close"></i></button>
  </div>
  <div class="modal-body">
    <div class="modal-content">
      <div class="row" *ngIf="!!settings?.customHTML || !!context?.message">
        <div *ngIf="!settings.enableHTML" class="twelve">
          {{context.message}}
        </div>
        <div *ngIf="settings.enableHTML" [innerHTML]="context.message" class="twelve"></div>
        <div *ngIf="!!settings.customHTML" [innerHTML]="settings.customHTML"></div>
      </div>
      <div class="row modal-actions">
        <button id="confirm-btn" data-cy="confirm-btn" class="button {{settings?.invertButtonColors? 'secondary' : 'primary'}}" (click)="OK()">
          {{settings.okText || 'OK'}}
        </button>
        <button id="cancel-btn" data-cy="cancel-btn" class="button {{settings?.invertButtonColors? 'primary' : 'secondary'}}" (click)="cancel()"
          *ngIf="settings.cancelText">
          {{settings.cancelText || 'Cancel'}}
        </button>
      </div>
    </div>
  </div>
</div>
